@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* for sweetalert  */
@import url('./swal.css');

* {
  font-family: var(--font--noto-sans-tc), var(--font--noto-sans), sans-serif !important;
}

/* For Navbar */
:root {
  --browser-main-nav-height: 60px;
  --browser-sub-nav-height: 30px;
  --app-nav-height: 44px;
}

@layer components {
  .wu-toast-container .Toastify__progress-bar--info {
    @apply bg-grayscale-300;
  }

  .wu-toast-container .Toastify__progress-bar--success {
    @apply bg-green-500;
  }

  .wu-toast-container .Toastify__progress-bar--warning {
    @apply bg-yellow-500;
  }

  .wu-toast-container .Toastify__progress-bar--error {
    @apply bg-peach-500;
  }

  .wu-toast-container .Toastify__toast-body {
    @apply text-grayscale-800 pr-2 text-sm font-bold;
  }

  .wu-toast-container .Toastify__toast {
    @apply min-h-[2.875rem] px-2 py-3;
  }
}
